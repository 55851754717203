<template>
  <router-view/>
</template>

<script>
export default {
  name: 'LayoutNoHeaderFooter',
  props: {
    school: Object,
    user: Object
  }
}
</script>
